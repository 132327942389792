import {AffiliateSubType, CommissionAmountType} from '~/gql/generated';

import {formatDollars} from '../Product/utils/productUtils';

/**
 * Writes the CSS variables to a style tag in head of root
 */
export function getExpertThemeCSS(theme: Record<string, any>): string {
  const prefix = '--expert-palette';
  const variables = getThemeVariable(theme.palette || {}, prefix, {});
  return `:root {
      ${Object.keys(variables)
        .map((key) => `${key}: ${formatHex(variables[key])};`, [])
        .join(' \n ')}
  }`;
}

function formatHex(value: string) {
  return value.startsWith('#') ? value : `#${value}`;
}

function getThemeVariable(
  theme: Record<string, unknown>,
  prefix: string,
  result: Record<string, string>,
) {
  Object.keys(theme).map((key) => {
    const value = theme[key];
    if (typeof value === 'string') {
      result[`${prefix}-${key}`] = value;
    } else if (value && value !== null && typeof value === 'object') {
      // @ts-expect-error
      getThemeVariable(value, `${prefix}-${key}`, result);
    }
  });

  return result;
}

export function calculateCommissionAmount(
  price: string,
  commissionType: CommissionAmountType | undefined,
  commissionValue: string,
  isPerSale?: boolean,
  affiliateSubType?: AffiliateSubType,
): string {
  const postFix = isPerSale ? '/sale' : '';
  if (affiliateSubType === AffiliateSubType.Amazon) {
    return `2% - 5%${postFix}`;
  }
  if (affiliateSubType === AffiliateSubType.Impact) {
    return 'Varies';
  }
  if (price === '' || commissionType === undefined || commissionValue === '') {
    return "";
  }

  const amount =
    commissionType === CommissionAmountType.Flat
      ? commissionValue
      : ((parseFloat(price) * parseFloat(commissionValue)) / 100).toFixed(2);

  return `${formatDollars(amount)}${postFix}`;
}

export const invalidCreatorNames = [
  'store',
  'demostore',
  'newbrand',
  'build',
  'images',
  'www',
  'cart.json',
];

export function parseTheme(data: string) {
  let theme = JSON.parse(data || '{}');
  if (typeof theme !== 'object') {
    theme = JSON.parse(theme || '{}');
  }

  return theme;
}
